import { useEffect, useState } from 'react'
import { Controller, FieldValues, Path } from 'react-hook-form'

import {
  Autocomplete,
  Select,
  Switch,
  Textfield
} from '@clientbase/clientbase-library'
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import { DataService } from 'api/DataService'
import { AutoCompleteBillingsParams } from 'models/Billings/AutoComplete'
import { expirationOptions } from 'templates/Charge/Modals/EditRecurrence/templates/ExpirationOptions.utils'

import AutocompleteCustomers from 'components/AutocompleteCustomers'
import AutocompleteProducts from 'components/AutocompleteProducts'
import { ProductAutocomplete } from 'components/AutocompleteProducts/AutocompleteProducts'
import { DatePicker } from 'components/DatePicker'

import { InfosProps } from './Infos.interface'

const Infos = <T extends FieldValues>({
  control,
  isCharge,
  isEnablePlan,
  plan,
  nameCustomer,
  setCustomerUuid,
  edit,
  setRecieverType,
  recieverType,
  setGroupUuid,
  setProductName,
  limit_days_for_payment,
  watch
}: InfosProps<T>) => {
  const [groups, setGroups] = useState<AutoCompleteBillingsParams[]>([])

  const getAutocompleteGroups = async () => {
    const responseGroupsAutocomplete = await DataService({
      url: '/v1/customer_groups/autocomplete',
      type: 'GET'
    })

    if (responseGroupsAutocomplete.status === 200) {
      const groupsList: AutoCompleteBillingsParams[] =
        responseGroupsAutocomplete.data.records.map(
          (record: { name: string; uuid: string }) => ({
            label: record.name,
            value: record.uuid
          })
        )

      setGroups(groupsList)
    }
  }

  useEffect(() => {
    if (watch('recieverType' as Path<T>) == 'group') {
      getAutocompleteGroups()
    }
  }, [watch('recieverType' as Path<T>)])

  return (
    <>
      {edit && nameCustomer && (
        <Textfield placeholder="Buscar cliente" value={nameCustomer} disabled />
      )}

      {!edit && (
        <>
          {!isCharge && (
            <Box mb={isEnablePlan ? '32px' : '4px'} width="100%">
              <Controller
                control={control}
                name={'isEnablePlan' as Path<T>}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Box display="flex" alignItems="center" gap="8px">
                      <Typography variant="smBold">
                        Vincular um produto na recorrência
                      </Typography>
                      <Switch
                        checked={value}
                        onChange={onChange}
                        name="isEnablePlan"
                      />
                    </Box>
                  )
                }}
              />
              {isEnablePlan && (
                <Controller
                  control={control}
                  name={'plan' as Path<T>}
                  render={({
                    field: { onChange, value },
                    fieldState: { error }
                  }) => {
                    return (
                      <AutocompleteProducts
                        value={value}
                        onChange={(
                          _: React.SyntheticEvent,
                          value: {
                            label: string
                            value: string
                            product: ProductAutocomplete
                          }
                        ) => {
                          if (!value) return
                          !!setProductName && setProductName(value.product.name)
                          onChange(value.value)
                        }}
                        errorMessage={error?.message}
                        placeholder="Selecione um produto para vincular"
                        disabled={!!plan}
                        product={plan}
                      />
                    )
                  }}
                />
              )}
            </Box>
          )}

          <Controller
            name={'recieverType' as Path<T>}
            control={control}
            render={({ field }) => (
              <RadioGroup
                aria-labelledby="group-type"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event)
                  !!setRecieverType &&
                    setRecieverType(
                      event.target.value as 'customer' | 'group' | undefined
                    )
                }}
                row
                sx={{ gap: 2 }}
              >
                <FormControlLabel
                  value="customer"
                  control={<Radio />}
                  label="Cliente"
                />
                <FormControlLabel
                  value="group"
                  control={<Radio />}
                  label="Grupo"
                />
              </RadioGroup>
            )}
          />

          {recieverType === 'customer' ? (
            <Controller
              control={control}
              name={'customer' as Path<T>}
              render={({
                field: { onChange, value, ref },
                fieldState: { error }
              }) => {
                const errorTyped = error as any
                return (
                  <AutocompleteCustomers
                    onChange={(_: React.SyntheticEvent, value: unknown) => {
                      const option = value as AutoCompleteBillingsParams
                      setCustomerUuid && setCustomerUuid(option?.value)
                      onChange(option ?? { value: '', label: '' })
                    }}
                    inputRef={ref}
                    value={value}
                    errorMessage={errorTyped?.label?.message}
                    placeholder="Buscar cliente"
                    nameCustomer={nameCustomer}
                  />
                )
              }}
            />
          ) : null}

          {recieverType === 'group' && (
            <Controller
              control={control}
              name={'group' as Path<T>}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => {
                const errorTyped = error as any
                return (
                  <Autocomplete
                    value={value}
                    onChange={(_, value) => {
                      const option = value as AutoCompleteBillingsParams
                      setGroupUuid && setGroupUuid(option.value)
                      onChange(option ?? { value: '', label: '' })
                    }}
                    renderOption={(props, option) => {
                      const optionTyped = option as AutoCompleteBillingsParams
                      return (
                        <Box component="li" {...props} key={optionTyped.value}>
                          {optionTyped.label}
                        </Box>
                      )
                    }}
                    options={groups || []}
                    renderInput={(params) => (
                      <Textfield
                        helperText={errorTyped?.label?.message as any}
                        error={!!errorTyped?.label?.message as any}
                        placeholder="Buscar grupo"
                        name="group"
                        {...params}
                      />
                    )}
                  />
                )
              }}
            />
          )}
        </>
      )}
      <Grid container={true} mt="0px" spacing={2}>
        <Grid item={true} xs={4} alignSelf={'end'}>
          <Controller
            control={control}
            name={'amountBilled' as Path<T>}
            render={({
              field: { onChange, value, ref },
              fieldState: { error }
            }) => (
              <Textfield
                customStartAdornment={
                  <Typography mr="6px" variant="mdLight">
                    R$
                  </Typography>
                }
                inputRef={ref}
                helperText={error?.message}
                error={!!error?.message}
                label="Qual o valor a ser cobrado?"
                mask="money"
                name="amountBilled"
                onChange={onChange}
                startAdornment={true}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item={true} xs={4}>
          <Controller
            control={control}
            name={'dueDate' as Path<T>}
            render={({
              field: { onChange, value, ref },
              fieldState: { error }
            }) => {
              return (
                <DatePicker
                  inputRef={ref}
                  helperText={error?.message}
                  error={!!error?.message}
                  label={
                    isCharge
                      ? 'Qual a data de vencimento?'
                      : 'Qual o vencimento da primeira fatura?'
                  }
                  minDate={new Date()}
                  maxDate={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() + 10)
                    )
                  }
                  onChange={onChange}
                  renderInput={(props: any) => (
                    <Textfield {...props} name="dueDate" />
                  )}
                  toolbarPlaceholder="Escolha a data de vencimento"
                  value={value ? value : null}
                />
              )
            }}
          />
        </Grid>
        <Grid item={true} xs={4}>
          <Controller
            control={control}
            name={'expirationField' as Path<T>}
            render={({
              fieldState: { error },
              field: { onChange, value, ref }
            }) => (
              <Select
                inputRef={ref}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400
                    }
                  }
                }}
                label={`Expiração da ${
                  isCharge || edit ? 'cobrança' : 'recorrência'
                }`}
                helperText={error?.message}
                error={!!error?.message}
                fullWidth
                name="expirationField"
                defaultValue={limit_days_for_payment}
                value={parseInt(value) || 1}
                displayEmpty
                onChange={onChange}
              >
                {' '}
                {expirationOptions.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Infos
