import React from 'react'
import { toast } from 'react-toastify'

import { Box } from '@mui/material'
import { useAuthContext } from 'context/AuthContext'
import copy from 'copy-to-clipboard'

import InviteAndEarnClipBoard from 'components/Icons/InviteAndEarnClipBoard'

import * as S from './styles'

const CopyLinkField = () => {
  const { user } = useAuthContext()
  const link = `https://base.clientbase.com.br/registrar?merchant_referral_uuid=${user?.uuid}`
  const handleCopy = () => {
    copy(link)
    toast.success(`Link copiado com sucesso.`)
  }
  return (
    <S.Wrapper onClick={handleCopy}>
      <S.Text>{link}</S.Text>
      <Box display="flex" justifyContent="center">
        <InviteAndEarnClipBoard />
      </Box>
    </S.Wrapper>
  )
}

export default CopyLinkField
