import { Button, styled } from '@mui/material'

import { theme } from 'styles/theme'

type WrapperProps = {
  isExpandedMobileMenu: boolean
}

export const Wrapper = styled('div')<WrapperProps>(
  ({ isExpandedMobileMenu }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 22,
    backgroundColor: theme.palette.neutral[800],
    width: '90%',
    overflowY: 'scroll',
    zIndex: 800,
    height: isExpandedMobileMenu ? 'calc(100vh - 120px)' : 0,
    transition: 'height 0.5s ease-in-out'
  })
)

export const BalanceButton = styled(Button)(() => ({
  ['&:focus']: {
    borderColor: 'black'
  },
  ['&:hover, &:focus']: {
    background: 'none'
  },
  alignItems: 'flex-start',
  border: '1px solid transparent',
  cursor: 'pointer',
  flexDirection: 'column'
}))

export const AccountDetails = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '90%'
}))

export const MenuItem = styled('div')(() => ({
  display: 'flex',
  gap: 10,
  padding: '8px 0',
  width: '100%',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#E9EBE9'
  }
}))
