import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'
import styled from '@mui/system/styled'

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary[700]
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary[700],
      background: theme.palette.primary[700],
      color: '#fff'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary[700],
    borderTopWidth: 3,
    borderRadius: 1
  }
}))

export const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean }
}>(({ ownerState, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'transparent',
  borderRadius: 1000,
  '& .QontoStepIcon-completedIcon': {
    color: '#fff',
    background: theme.palette.primary[700],
    boxShadow: 'none',
    zIndex: 1,
    fontSize: 18,
    borderRadius: '50%'
  },
  '& .QontoStepIcon-circle': {
    boxShadow: `inset 0 0 0 3px ${theme.palette.primary[700]}`,
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  ...(ownerState.active && {
    color: 'transparent'
  })
}))
