export const Options = [
  {
    label: '1 dia',
    value: 1
  },
  {
    label: '2 dias',
    value: 2
  },
  {
    label: '3 dias',
    value: 3
  },
  {
    label: '4 dias',
    value: 4
  },
  {
    label: '5 dias',
    value: 5
  },
  {
    label: '6 dias',
    value: 6
  },
  {
    label: '7 dias',
    value: 7
  },
  {
    label: '8 dias',
    value: 8
  },
  {
    label: '9 dias',
    value: 9
  },
  {
    label: '10 dias',
    value: 10
  },
  {
    label: '11 dias',
    value: 11
  },
  {
    label: '12 dias',
    value: 12
  },
  {
    label: '13 dias',
    value: 13
  },
  {
    label: '14 dias',
    value: 14
  },
  {
    label: '15 dias',
    value: 15
  },
  {
    label: '16 dias',
    value: 16
  },
  {
    label: '17 dias',
    value: 17
  },
  {
    label: '18 dias',
    value: 18
  },
  {
    label: '19 dias',
    value: 19
  },
  {
    label: '20 dias',
    value: 20
  },
  {
    label: '21 dias',
    value: 21
  },
  {
    label: '22 dias',
    value: 22
  },
  {
    label: '23 dias',
    value: 23
  },
  {
    label: '24 dias',
    value: 24
  },
  {
    label: '25 dias',
    value: 25
  },
  {
    label: '26 dias',
    value: 26
  },
  {
    label: '27 dias',
    value: 27
  },
  {
    label: '28 dias',
    value: 28
  },
  {
    label: '29 dias',
    value: 29
  },
  {
    label: '30 dias',
    value: 30
  },
  {
    label: '31 dias',
    value: 31
  }
]
