import { useState } from 'react'
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form'

import { Select, Switch } from '@clientbase/clientbase-library'
import {
  Box,
  FormControlLabel,
  MenuItem,
  Popper,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'

import { FormProductParams } from '../../FormProducts.schema'
import * as S from './TypesCharge.styles'
import { Options } from './TypesCharge.utils'

const message = {
  0: (dueType: string) =>
    dueType === 'immediate_charge'
      ? 'Seu cliente será cobrado no mesmo momento em que cadastrar o cartão de crédito. Se a compra for feita via PIX ou Boleto, a confirmação de pagamento acontece em até 3 dias úteis.'
      : dueType === 'delay_charge'
      ? 'Seu cliente será cobrado depois da quantidade de dias que você escolher. Ou seja, se hoje é dia 12 e você escolher que ele seja cobrado em 5 dias, dia 17 será feita essa cobrança no cartão ou mandado um lembrete de vencimento de boleto para ele.'
      : 'Seu cliente será cobrado na data que você escolher.',
  1: (dueType: string) =>
    dueType === 'immediate_charge'
      ? 'Seu cliente será cobrado no mesmo momento em que cadastrar o cartão de crédito ou pagar o pix ou boleto. E a recorrência será gerada no dia que foi feito o pagamento. Ou seja, se ele pagou dia 12, todo dia 12 será cobrado novamente.'
      : dueType === 'delay_charge'
      ? 'Seu cliente será cobrado depois da quantidade de dias que você escolher. Ou seja, se hoje é dia 12 e você escolher que ele seja cobrado em 5 dias, dia 17 será feita essa cobrança no cartão ou mandado um lembrete de vencimento de boleto para ele. A partir disso, todo dia 17 ele será cobrado.'
      : 'Seu cliente será cobrado todo mês no dia que você escolher.'
}

type TypesChargesProps<T extends FieldValues> = {
  tab?: string | number
  control: Control<T>
  watch: UseFormWatch<T>
  setValue: UseFormSetValue<FormProductParams>
}

const TypesCharges = <T extends FieldValues>({
  tab,
  control,
  watch,
  setValue
}: TypesChargesProps<T>) => {
  const { dueType } = watch()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [type, setType] = useState<string | null>(null)

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    type: string
  ) => {
    setAnchorEl(event.currentTarget)
    setType(type)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setType(null)
  }

  const open = Boolean(anchorEl)

  return (
    <S.Wrapper>
      <Typography sx={{ marginBottom: '-8px', marginTop: '22px' }}>
        {dueType === 'delay_charge'
          ? 'data do primeiro vencimento:'
          : 'Vencimento:'}
      </Typography>
      <S.Accordion expanded={true}>
        <S.AccordionSummary>
          <Controller
            control={control}
            name={'dueType' as Path<T>}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                value={value}
                onChange={(_, value) => {
                  setValue(
                    'dueDay',
                    value === 'delay_charge' || value === 'specified_charge'
                      ? 1
                      : undefined
                  )
                  onChange(value)
                }}
                row
              >
                <FormControlLabel
                  value="immediate_charge"
                  control={<Radio />}
                  label="Na hora"
                  onMouseEnter={(event) =>
                    handlePopoverOpen(event, 'immediate_charge')
                  }
                  onMouseLeave={handlePopoverClose}
                />
                <FormControlLabel
                  value="delay_charge"
                  control={<Radio />}
                  label="Após alguns dias"
                  onMouseEnter={(event) =>
                    handlePopoverOpen(event, 'delay_charge')
                  }
                  onMouseLeave={handlePopoverClose}
                />
                <FormControlLabel
                  aria-haspopup="true"
                  value="specified_charge"
                  control={<Radio />}
                  label="Em um dia específico"
                  onMouseEnter={(event) =>
                    handlePopoverOpen(event, 'specified_charge')
                  }
                  onMouseLeave={handlePopoverClose}
                />
                <Popper
                  sx={{
                    pointerEvents: 'none',
                    zIndex: 999999,
                    '.MuiPaper-root': {
                      width: '100%',
                      maxWidth: '380px',
                      padding: '12px',
                      background: '#545454',
                      color: '#fff'
                    }
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  placement="top"
                >
                  <S.PopperWrapper>
                    {message[Number(tab)](type)}
                  </S.PopperWrapper>
                </Popper>
              </RadioGroup>
            )}
          />
        </S.AccordionSummary>
        {dueType !== 'immediate_charge' ? (
          <S.AccordionDetails>
            {dueType === 'delay_charge' ? (
              <Box>
                <Controller
                  name={'dueDay' as Path<T>}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error }
                  }) => (
                    <Select
                      helperText={error?.message}
                      error={!!error?.message}
                      displayEmpty
                      fullWidth
                      onChange={onChange}
                      value={value}
                    >
                      {Options.map(({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>
            ) : (
              <Box>
                <Box display="flex">
                  <Controller
                    name={'dueDay' as Path<T>}
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error }
                    }) => (
                      <Box width={'100%'}>
                        <Select
                          helperText={error?.message}
                          error={!!error?.message}
                          displayEmpty
                          fullWidth
                          onChange={onChange}
                          value={value}
                        >
                          {Array.from({ length: 31 }, (_, i) => (
                            <MenuItem key={i + 1} value={i + 1}>
                              {`Dia ${i + 1} `}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )}
                  />
                  {tab === 1 ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      whiteSpace="nowrap"
                    >
                      <Controller
                        control={control}
                        name={'proratedCharge' as Path<T>}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Switch
                              onChange={onChange}
                              value={value}
                              checked={value}
                            />{' '}
                            Cobrar proporcional
                          </>
                        )}
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            )}
          </S.AccordionDetails>
        ) : null}
      </S.Accordion>
    </S.Wrapper>
  )
}

export default TypesCharges
