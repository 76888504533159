import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { Button, Dialog, Modal, Textarea } from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import { DataService } from 'api/DataService'
import { parseCookies, setCookie } from 'nookies'
import * as yup from 'yup'

import { theme } from 'styles/theme'

import * as S from './styles'

interface NPSForm {
  score: number | null
  benefits: string
  suggestions: string
}

const schemaForm = yup.object({
  score: yup.number(),
  benefits: yup.string(),
  suggestions: yup.string()
})

const ModalSurveyNPS = () => {
  const { control, watch } = useForm<NPSForm>({
    defaultValues: {
      score: undefined,
      benefits: '',
      suggestions: ''
    },
    resolver: yupResolver(schemaForm)
  })

  const { score, benefits, suggestions } = watch()

  const [isOpenModalSurvey, setIsOpenModalSurvey] = useState(true)
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const { ['nextauth.merchant_user_uuid']: merchant_user_uuid } = parseCookies()
  const { ['nextauth.merchant_user_name']: userName } = parseCookies()
  const handleClick = async () => {
    if (score == null || !benefits.length || !suggestions.length) {
      toast.error('Você precisa preencher todos os campos.')
    } else {
      const response = await DataService({
        data: {
          score,
          benefits,
          suggestions,
          merchant_user_uuid
        },
        type: 'POST',
        url: '/v1/nps_answers'
      })
      if (response?.error) {
        toast.error('Houve um erro ao enviar a avaliação')
      }
      setCookie(undefined, 'nextauth.show_nps', 'false', {
        maxAge: 60 * 60 * 24 * 6
      })
      setIsOpenModalSurvey(false)
      setIsOpenDialog(true)
    }
  }

  const handleCloseSurvey = () => {
    setIsOpenModalSurvey(false)
  }

  return (
    <>
      <Modal
        width={'auto + 0%'}
        height={'auto !important'}
        handleClose={handleCloseSurvey}
        open={isOpenModalSurvey}
        size="large"
      >
        <S.Wrapper>
          <Box display="flex" flexDirection="column">
            <S.Heading mb={4} variant="lgBold">
              Olá, {userName}
            </S.Heading>
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              flexDirection={'column'}
              gap={2}
              mt={0}
            >
              <Typography>
                {' '}
                Desenvolvemos esta pesquisa de satisfação para entender melhor
                como podemos continuar evoluindo nossos serviços para você.
              </Typography>
              <Typography>
                Você consegue nos ajudar respondendo abaixo, por favor? 😊{' '}
                Obrigado!
              </Typography>
            </Box>
          </Box>

          <Typography variant="mdBold" mb={-1}>
            Como você se sentiria se não pudesse mais usar a Base de Clientes?
          </Typography>

          <Controller
            control={control}
            name="score"
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={
                    <Typography color={theme.palette.neutral[400]}>
                      Ficaria muito desapontado
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={
                    <Typography color={theme.palette.neutral[400]}>
                      Ficaria um pouco desapontado
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={
                    <Typography color={theme.palette.neutral[400]}>
                      Não ficaria desapontado
                    </Typography>
                  }
                />
              </RadioGroup>
            )}
          />

          <Typography variant="mdBold">
            Qual é o maior benefício que você tem com a Base de Clientes?
          </Typography>

          <Controller
            control={control}
            name="benefits"
            render={({ field }) => (
              <Textarea
                fullWidth={false}
                placeholder="Insira sua resposta..."
                maxLength={2000}
                height="60px"
                {...field}
              />
            )}
          />

          <Typography variant="mdBold">
            Como podemos melhorar a Base de Clientes para você?
          </Typography>

          <Controller
            control={control}
            name="suggestions"
            render={({ field }) => (
              <Textarea
                fullWidth={false}
                placeholder="Insira sua resposta..."
                maxLength={2000}
                height="60px"
                {...field}
              />
            )}
          />
        </S.Wrapper>

        <Box display={'flex'} justifyContent={'end'} mt={4}>
          <Button
            hasFocus
            sx={{
              backgroundColor: '#01F7C2',
              color: 'black',
              px: 5,
              '&:hover': {
                backgroundColor: '#01F7C2',
                px: 5
              }
            }}
            bgColor="#01F7C2"
            variantButton="filters"
            onClick={handleClick}
            key={'s'}
          >
            Enviar resposta
          </Button>
        </Box>
      </Modal>
      <Dialog
        setIsOpenDialog={() => setIsOpenDialog(false)}
        isOpenDialog={isOpenDialog}
        icon="check"
        iconColor="green"
        title="Obrigado!"
        description="Agradecemos a sua resposta! 😃"
      >
        <Button
          onClick={() => setIsOpenDialog(false)}
          variantButton="green"
          fullWidth
          hasFocus
        >
          OK
        </Button>
      </Dialog>
    </>
  )
}
export default ModalSurveyNPS
