export interface UserParams {
  name?: string | null
  status?: string | null
  role?: string | null
  phone?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  email?: string | null
  uuid?: string | null
  merchantUuid?: string | null
}

export function constructorUser(data: any) {
  const user: UserParams = {
    name: data?.name,
    status: data?.status,
    role: data?.role,
    phone: data?.phone,
    createdAt: data?.created_at,
    updatedAt: data?.updated_at,
    email: data?.email,
    uuid: data?.uuid,
    merchantUuid: data?.merchant_uuid
  }

  return user
}

export const roles = {
  admin: {
    HEADER: {
      VIEW_BALACE: true,
      MODAL_BALACE: {
        REPORTS_EXPORT: true,
        TRANSFER: true
      }
    },
    SETTINGS: {
      VIEW_PERFIL: true,
      PERFIL: {
        EDIT: true
      },
      VIEW_CHANGE_PASSWORD: true,
      VIEW_COLLECTION_RULE: true,
      COLLECTION_RULE: {
        EDIT: true
      },
      VIEW_USERS: true,
      USERS: {
        DELETE: true,
        ADD: true,
        EDIT: true
      },
      VIEW_PORTAL_CUSTOMIZATION: true,
      PORTAL_CUSTOMIZATION: {
        EDIT: true
      }
    },
    MENU: {
      VIEW_SETTINGS: true,
      VIEW_REPORTS: true,
      VIEW_HIDE_BALACE: true
    },
    OVERVIEW: {
      VIEW_CUSTOMER_MANAGED: true,
      VIEW_CUSTOMERS: true,
      VIEW_ACCESS_PORTAL: true,
      VIEW_TICKED_AVG: true,
      VIEW_NONCOMPLIANCE: true,
      VIEW_RECURRENCES_ACTIVE: true,
      VIEW_INVOICING: true,
      VIEW_LAST_ACTIVITIES: true
    },
    CUSTOMER: {
      GROUP_ADD: true,
      GROUP_SEARCH: true,
      CUSTOMER_ADD: true,
      REPORTS_EXPORT: true,
      BILLING_ADD: true,
      VIEW_MODAL_DETAIL: true,
      MODAL_DETAIL: {
        CUSTOMER_ARCHIVED: true,
        CUSTOMER_EDIT: true,
        CUSTOMER_DELETE: true,
        BILLING_ADD: true,
        BILLING_MARK_PAY: true,
        RECURRENCE_ADD: true
      }
    },
    CHARGE: {
      TAB_CHARGE: {
        VIEW: true,
        STATISTICS: true,
        REPORTS_EXPORT: true,
        VIEW_BILLING: true,
        VIEW_INVOICE: true,
        VIEW_DETAIL_INVOICE: true,
        BILLING_ADD: true,
        MODAL_DETAIL_INVOICE: {
          CANCEL_INVOICE: true,
          EDIT_INVOICE: true,
          ANTICIPATE_INVOICE: true,
          MARK_PAY_INVOICE: true
        }
      },
      TAB_RECURRENCE: {
        VIEW: true,
        STATISTICS: true,
        REPORTS_EXPORT: true, // DISABLED
        RECURRENCE_ADD: true,
        VIEW_DETAIL_RECURRENCE: true,
        MODAL_DETAIL_RECURRENCE: {
          EDIT_RECURRENCE: true,
          CANCEL_RECURRENCE: true,
          VIEW_DETAIL_BILLING: true
        }
      },
      TAB_TRANSFER: {
        VIEW: true,
        REPORTS_EXPORT: true,
        VIEW_DETAIL: true
      }
    }
  },
  guest: {
    HEADER: {
      VIEW_BALACE: false,
      MODAL_BALACE: {
        REPORTS_EXPORT: false,
        TRANSFER: false
      }
    },
    SETTINGS: {
      VIEW_PERFIL: false,
      PERFIL: {
        EDIT: false
      },
      VIEW_CHANGE_PASSWORD: true,
      VIEW_COLLECTION_RULE: false,
      COLLECTION_RULE: {
        EDIT: false
      },
      VIEW_USERS: false,
      USERS: {
        DELETE: false,
        ADD: false,
        EDIT: false
      }
    },
    MENU: {
      VIEW_SETTINGS: true,
      VIEW_REPORTS: false,
      VIEW_HIDE_BALACE: false
    },
    OVERVIEW: {
      VIEW_CUSTOMER_MANAGED: true,
      VIEW_CUSTOMERS: true,
      VIEW_ACCESS_PORTAL: true,
      VIEW_TICKED_AVG: false,
      VIEW_NONCOMPLIANCE: false,
      VIEW_RECURRENCES_ACTIVE: false,
      VIEW_INVOICING: false,
      VIEW_LAST_ACTIVITIES: false
    },
    CUSTOMER: {
      GROUP_ADD: false,
      GROUP_SEARCH: false,
      CUSTOMER_ADD: false,
      REPORTS_EXPORT: false,
      BILLING_ADD: false,
      VIEW_MODAL_DETAIL: true,
      MODAL_DETAIL: {
        CUSTOMER_ARCHIVED: false,
        CUSTOMER_EDIT: false,
        CUSTOMER_DELETE: false,
        BILLING_ADD: false,
        BILLING_MARK_PAY: false,
        RECURRENCE_ADD: false
      }
    },
    CHARGE: {
      TAB_CHARGE: {
        VIEW: true,
        STATISTICS: false,
        REPORTS_EXPORT: false,
        VIEW_BILLING: false,
        VIEW_INVOICE: false,
        VIEW_DETAIL_INVOICE: false,
        BILLING_ADD: false,
        MODAL_DETAIL_INVOICE: {
          CANCEL_INVOICE: false,
          EDIT_INVOICE: false,
          ANTICIPATE_INVOICE: false,
          MARK_PAY_INVOICE: false
        }
      },
      TAB_RECURRENCE: {
        VIEW: true,
        STATISTICS: false,
        REPORTS_EXPORT: false, // DISABLED
        RECURRENCE_ADD: false,
        VIEW_DETAIL_RECURRENCE: false,
        MODAL_DETAIL_RECURRENCE: {
          EDIT_RECURRENCE: false,
          CANCEL_RECURRENCE: false,
          VIEW_DETAIL_BILLING: false
        }
      },
      TAB_TRANSFER: {
        VIEW: false,
        REPORTS_EXPORT: false,
        VIEW_DETAIL: false
      }
    }
  },
  user: {
    HEADER: {
      VIEW_BALACE: true,
      MODAL_BALACE: {
        REPORTS_EXPORT: true,
        TRANSFER: false
      }
    },
    SETTINGS: {
      VIEW_PERFIL: true,
      PERFIL: {
        EDIT: true
      },
      VIEW_CHANGE_PASSWORD: true,
      VIEW_COLLECTION_RULE: true,
      COLLECTION_RULE: {
        EDIT: true
      },
      VIEW_USERS: true,
      USERS: {
        DELETE: false,
        ADD: false,
        EDIT: false
      },
      VIEW_PORTAL_CUSTOMIZATION: true,
      PORTAL_CUSTOMIZATION: {
        EDIT: true
      }
    },
    MENU: {
      VIEW_SETTINGS: true,
      VIEW_REPORTS: true,
      VIEW_HIDE_BALACE: true
    },
    OVERVIEW: {
      VIEW_CUSTOMER_MANAGED: true,
      VIEW_CUSTOMERS: true,
      VIEW_ACCESS_PORTAL: true,
      VIEW_TICKED_AVG: true,
      VIEW_NONCOMPLIANCE: true,
      VIEW_RECURRENCES_ACTIVE: true,
      VIEW_INVOICING: true,
      VIEW_LAST_ACTIVITIES: true
    },
    CUSTOMER: {
      GROUP_ADD: true,
      GROUP_SEARCH: true,
      CUSTOMER_ADD: true,
      REPORTS_EXPORT: true,
      BILLING_ADD: true,
      VIEW_MODAL_DETAIL: true,
      MODAL_DETAIL: {
        CUSTOMER_ARCHIVED: true,
        CUSTOMER_EDIT: true,
        CUSTOMER_DELETE: true,
        BILLING_ADD: true,
        BILLING_MARK_PAY: true,
        RECURRENCE_ADD: true
      }
    },
    CHARGE: {
      TAB_CHARGE: {
        VIEW: true,
        STATISTICS: true,
        REPORTS_EXPORT: true,
        VIEW_BILLING: true,
        VIEW_INVOICE: true,
        VIEW_DETAIL_INVOICE: true,
        BILLING_ADD: true,
        MODAL_DETAIL_INVOICE: {
          CANCEL_INVOICE: true,
          EDIT_INVOICE: true,
          ANTICIPATE_INVOICE: true,
          MARK_PAY_INVOICE: true
        }
      },
      TAB_RECURRENCE: {
        VIEW: true,
        STATISTICS: true,
        REPORTS_EXPORT: true, // DISABLED
        RECURRENCE_ADD: true,
        VIEW_DETAIL_RECURRENCE: true,
        MODAL_DETAIL_RECURRENCE: {
          EDIT_RECURRENCE: true,
          CANCEL_RECURRENCE: true,
          VIEW_DETAIL_BILLING: true
        }
      },
      TAB_TRANSFER: {
        VIEW: true,
        REPORTS_EXPORT: true,
        VIEW_DETAIL: true
      }
    }
  }
} as const

export type Roles = typeof roles
