import { Icon } from '@clientbase/clientbase-library'
import { Box, Tooltip } from '@mui/material'
import moment from 'moment'
import InvoiceIcon from 'templates/Invoice/components/InvoiceIcon'
import {
  DataCustomerCharges,
  StatisticsMapperParams
} from 'templates/v2/Charge/components/ChargeTable/ChargeTable'

import { ButtonRounded } from 'components/button'
import Can from 'components/Can'
import { Info } from 'components/InfoList/InfoList'

import { formatDate } from 'utils/FormatDate'
import { formatMoney } from 'utils/FormatMoney'

import { theme } from 'styles/theme'

import { ToggleModalsParams } from '../../Charge'
import * as S from './ChargeTable.styles'

const getStatusOptionCharge = (status: string, dueDate?: string) => {
  const today = moment().startOf('day').format('YYYY-MM-DD')
  switch (status) {
    case 'pending':
      return {
        label: 'Pendente',
        color: theme.palette.neutral[700],
        disabled: true
      }
    case 'created_payment':
      return {
        label: 'Pendente',
        color: theme.palette.neutral[700],
        disabled: true
      }
    case 'open_payment':
      if (dueDate && moment(dueDate).isBefore(today)) {
        return {
          label: 'Atrasada',
          color: theme.palette.alert[300]
        }
      }
      return {
        label: 'Aberta',
        color: theme.palette.secondary[300]
      }
    case 'no_payment':
      return {
        label: 'Expirada',
        color: theme.palette.error[300]
      }
    case 'expiring':
      return {
        color: theme.palette.error[400],
        label: 'Expirada'
      }
    case 'paid':
      return {
        label: 'Paga',
        color: theme.palette.primary[200]
      }
    case 'cancelling':
      return {
        label: 'Cancelada',
        color: theme.palette.error[300]
      }
    case 'cancelled':
      return {
        label: 'Cancelada',
        color: theme.palette.error[300]
      }
    case 'expired':
      return {
        label: 'Expirada',
        color: theme.palette.error[300]
      }
    case 'suspended':
      return {
        label: 'Suspensa',
        color: theme.palette.neutral[700]
      }
    default:
      window.newrelic.noticeError(
        new Error(`Erro ao mapear status da tabela cobranças: ${status}`)
      )
      return {
        label: 'Status desconhecido',
        color: theme.palette.neutral[300]
      }
  }
}

const getStatusOption = (status: string) => {
  switch (status) {
    case 'scheduled':
      return {
        label: 'Será emitida quando paga',
        color: theme.palette.alert[300]
      }
    case 'issued':
      return {
        label: 'Emitida',
        color: theme.palette.primary[200]
      }
    case 'not_scheduled':
      return {
        label: 'Não está programada',
        color: theme.palette.neutral[500]
      }
    case 'failed':
      return {
        label: 'Erro ao emitir',
        color: theme.palette.error[300]
      }
    default:
      window.newrelic.noticeError(
        new Error(
          `Erro ao mapear status da tabela cobrança: ${status} (getStatusOption)`
        )
      )
      return {
        label: 'Status desconhecido',
        color: theme.palette.neutral[300]
      }
  }
}

type CustomerChargesTableMapperProps = {
  data: DataCustomerCharges
  actions: {
    toggleModals?: (params: ToggleModalsParams) => void
    AUTHORIZATION?: any
    notViewActions?: boolean
  }
}

export const customerChargesTableMapper = ({
  data,
  actions
}: CustomerChargesTableMapperProps) => ({
  totalCount: data?.meta?.total_count,
  rows: data?.records?.map((record) => ({
    uuid: record?.uuid,
    client: (
      <Box display="flex" alignItems="center" gap="11px">
        <p>{record?.customer?.name}</p>{' '}
        <span style={{ color: '#777877', fontSize: '12px' }}>
          {record?.customer?.nickname}
        </span>
      </Box>
    ),
    dueDate: <p>{formatDate(record?.due_date)}</p>,
    paymentDate: <p>{formatDate(record?.date_paid || '')}</p>,
    value: <p>{formatMoney(record?.amount_billed)}</p>,
    status: (
      <S.Status status={record?.status} dueDate={record.due_date}>
        {getStatusOptionCharge(record?.status, record?.due_date).label}
      </S.Status>
    ),
    action: (
      <Box display="flex" justifyContent="flex-end" maxWidth="300px">
        {!actions?.notViewActions && (
          <>
            <Can roles={['admin', 'financial_operator', 'operator', 'user']}>
              <Tooltip
                enterTouchDelay={0}
                arrow={true}
                placement="top"
                title={getStatusOption(record?.nfse_status).label}
              >
                <Box
                  onClick={() =>
                    record.nfse.uuid &&
                    actions.toggleModals &&
                    actions.toggleModals({
                      modal: 'isOpenDetailInvoice',
                      uuid: record.nfse.uuid
                    })
                  }
                >
                  <ButtonRounded variant="transparent">
                    <InvoiceIcon
                      color={getStatusOption(record?.nfse_status).color}
                    />
                  </ButtonRounded>
                </Box>
              </Tooltip>
            </Can>

            <Can roles={['admin', 'financial_operator', 'operator', 'user']}>
              <Tooltip
                arrow={true}
                placement="top"
                title={
                  !record?.recurrence_uuid
                    ? 'Cobrança única'
                    : 'Visualizar recorrência'
                }
              >
                <Box>
                  <ButtonRounded
                    disabled={!record?.recurrence_uuid}
                    onClick={() =>
                      actions.toggleModals &&
                      actions.toggleModals({
                        modal: 'isOpenModalRecurrenceDetail',
                        uuid: record.recurrence_uuid
                      })
                    }
                    variant="transparent"
                  >
                    <Icon
                      icon="cached"
                      sx={{
                        color:
                          theme.palette.neutral[
                            !record?.recurrence_uuid ? 700 : 200
                          ]
                      }}
                    />
                  </ButtonRounded>
                </Box>
              </Tooltip>
            </Can>

            <Can roles={['admin', 'financial_operator', 'operator', 'user']}>
              <Tooltip
                enterTouchDelay={0}
                arrow={true}
                placement="top"
                title="Visualizar fatura"
              >
                <Box>
                  <ButtonRounded
                    disabled={
                      getStatusOptionCharge(record.status || '').disabled
                    }
                    onClick={() => {
                      window.open(
                        `https://fatura.clientbase.com.br/${record.uuid}`,
                        '_blank'
                      )
                    }}
                    variant="transparent"
                  >
                    <Icon
                      icon="receipt"
                      sx={{
                        color: getStatusOptionCharge(record.status || '').color
                      }}
                    />
                  </ButtonRounded>
                </Box>
              </Tooltip>
            </Can>

            <Can roles={['admin', 'financial_operator', 'operator', 'user']}>
              <Tooltip
                enterTouchDelay={0}
                arrow={true}
                placement="top"
                title={
                  record.viewed
                    ? 'Cliente abriu a fatura'
                    : 'Cliente não abriu a fatura'
                }
              >
                <Box>
                  <ButtonRounded disabled={true} variant="transparent">
                    <Icon
                      icon="doneAll"
                      sx={{
                        color: record.viewed
                          ? theme.palette.primary[200]
                          : theme.palette.neutral[700]
                      }}
                    />
                  </ButtonRounded>
                </Box>
              </Tooltip>
            </Can>

            <Can roles={['admin', 'financial_operator', 'operator', 'user']}>
              <Tooltip
                enterTouchDelay={0}
                arrow={true}
                placement="top"
                title="Detalhes cobrança"
              >
                <Box>
                  <ButtonRounded
                    onClick={() =>
                      actions.toggleModals &&
                      actions.toggleModals({
                        modal: 'isOpenModalChargeDetail',
                        uuid: record.uuid
                      })
                    }
                    variant="transparent"
                    data-cy="open-billing"
                  >
                    <Icon
                      icon="settings"
                      sx={{ color: theme.palette.neutral[200] }}
                    />
                  </ButtonRounded>
                </Box>
              </Tooltip>
            </Can>
          </>
        )}
      </Box>
    )
  }))
})

export type CustomerCharges = ReturnType<typeof customerChargesTableMapper>

export const statisticsMapper = (statistics: StatisticsMapperParams) => {
  const {
    total_billed,
    total_count,
    total_paid,
    total_received,
    total_to_receive
  } = statistics

  return [
    {
      title: 'Faturas / pagas',
      subtitle: `${total_count} / ${total_paid}`
    },
    {
      title: 'Recebido',
      subtitle: formatMoney(total_received),
      filter: {
        name: 'status',
        value: 'paid'
      }
    },
    {
      title: 'A receber',
      subtitle: formatMoney(total_to_receive),
      filter: {
        name: 'status',
        value: 'open_payment'
      }
    },
    {
      title: 'Total',
      subtitle: formatMoney(total_billed),
      filter: {
        name: 'total',
        value: 'total'
      }
    }
  ] as Info[]
}

type StatisticsTransfersMapper = {
  total_count: number
  amount_total_failed: string
  amount_total_pending: string
  amount_total_received: string
}

export const statisticsTransfersMapper = (
  statistics: StatisticsTransfersMapper
) => {
  const {
    total_count,
    amount_total_failed,
    amount_total_pending,
    amount_total_received
  } = statistics

  return [
    {
      title: 'Número de transferências',
      subtitle: `${total_count}`
    },
    {
      title: 'Total pendente',
      subtitle: formatMoney(amount_total_pending),
      filter: {
        name: 'status',
        value: 'pending'
      }
    },
    {
      title: 'Total confirmado',
      subtitle: formatMoney(amount_total_received),
      filter: {
        name: 'status',
        value: 'succeeded'
      }
    },
    {
      title: 'Total cancelado',
      subtitle: formatMoney(amount_total_failed),
      filter: {
        name: 'total',
        value: 'canceled'
      }
    }
  ] as Info[]
}
