import React, { useEffect, useState } from 'react'

import { Button } from '@clientbase/clientbase-library'
import { Typography } from '@mui/material'
import { signIn } from 'next-auth/react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { theme } from 'styles/theme'

import * as S from './styles'
interface GoogleAuthParams {
  isLogin?: boolean
}
const GoogleAuth = ({ isLogin = false }: GoogleAuthParams) => {
  const { query } = useRouter()
  const [registerUrl, setRegisterUrl] = useState('/registrar')
  const { merchant_referral_uuid } = query
  useEffect(() => {
    if (merchant_referral_uuid) {
      setRegisterUrl(
        `/registrar?merchant_referral_uuid=${merchant_referral_uuid}`
      )
    }
  }, [])
  return (
    <div>
      <Button
        onClick={async () => {
          signIn('google', {
            callbackUrl: isLogin ? '/' : registerUrl,
            prompt: 'select_account'
          })
        }}
        fullWidth
        buttonSize="lg"
        variantButton="outlined"
      >
        <S.Wrapper>
          <Image
            src="/images/google.svg"
            alt="logo do google"
            height="18"
            width="20"
            quality={100}
          />
          <Typography color={theme.palette.neutral[400]}>
            Continuar com o Google
          </Typography>
        </S.Wrapper>
      </Button>
    </div>
  )
}

export default GoogleAuth
