import styled from '@mui/system/styled'
import moment from 'moment'
import { rgba } from 'polished'

import { theme } from 'styles/theme'

const getStatus = (status: string, dueDate?: string) => {
  const today = moment().startOf('day').format('YYYY-MM-DD')
  switch (status) {
    case 'pending':
      return {
        color: theme.palette.neutral[400],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
    case 'created_payment':
      return {
        color: theme.palette.neutral[400],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
    case 'open_payment':
      if (dueDate && moment(dueDate).isBefore(today)) {
        return {
          color: theme.palette.alert[300],
          background: rgba(theme.palette.alert[400], 0.3)
        }
      }
      return {
        color: theme.palette.secondary[200],
        background: rgba(theme.palette.secondary[200], 0.1)
      }
    case 'no_payment':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'paid':
      return {
        color: theme.palette.primary[200],
        background: rgba(theme.palette.primary[200], 0.2)
      }
    case 'cancelling':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'cancelled':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'expired':
      return {
        color: theme.palette.error[300],
        background: rgba(theme.palette.error[300], 0.1)
      }
    case 'suspended':
      return {
        color: theme.palette.neutral[700],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
    default:
      window.newrelic.noticeError(
        new Error(`Erro ao mapear estilos da tabela cobranças: ${status}`)
      )
      return {
        color: theme.palette.neutral[400],
        background: rgba(theme.palette.neutral[700], 0.3)
      }
  }
}

type StatusProps = {
  status: string
  dueDate?: string
}

export const Status = styled('div')<StatusProps>(({ status, dueDate }) => ({
  width: 'fit-content',
  padding: '4px 8px',
  fontWeight: 600,
  borderRadius: 9999,
  ...getStatus(status, dueDate)
}))
