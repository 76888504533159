import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Button, Textfield } from '@clientbase/clientbase-library'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography } from '@mui/material'
import { DataService } from 'api/DataService'
import { useOnboardingContext } from 'context/OnboardingContext'
import { ActivateAccountFormsProps } from 'templates/ActivateAccount/ActivateAccount'

import AutoCompleteBank from 'components/AutoCompleteBank'
import InputController from 'components/InputController'

import { theme } from 'styles/theme'

import { BankFormSchema, schemaBankForm } from './'

export const initialBankDataFormValue = {}

const BankDataForm = ({
  sucessSubmit,
  handleBackStep,
  onboarding
}: ActivateAccountFormsProps) => {
  const { handleChange, values, fetchOnboarding } = useOnboardingContext()

  const { handleSubmit, control } = useForm<BankFormSchema>({
    defaultValues: values['3'],
    resolver: yupResolver(schemaBankForm)
  })

  const onSubmit: SubmitHandler<BankFormSchema> = async (data) => {
    const { bank, ...rest } = data

    const response = await DataService({
      type: 'POST',
      data: {
        bank_account: {
          ...rest,
          bank_uuid: bank.uuid
        }
      },
      url: '/v1/bank_accounts'
    })

    const error = response?.status >= 400 ? true : false

    if (!error) {
      await DataService({
        type: 'PATCH',
        url: '/v1/onboarding/bank_account_saved'
      })

      handleChange(3, data)
    }

    return sucessSubmit({ error, message: response?.message })
  }

  useEffect(() => {
    if (!onboarding) {
      fetchOnboarding()
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Typography
          sx={{
            [theme.breakpoints.down(800)]: {
              fontSize: '18px'
            }
          }}
          variant="lgBold"
        >
          Informe os dados da sua conta bancária
        </Typography>
        <ul>
          <li>
            <Typography variant="mdLight">
              CPF deve receber em uma conta bancária PF, da mesma titularidade.
            </Typography>
          </li>
          <li>
            <Typography variant="mdLight">
              CNPJ deve receber em uma conta bancária PJ, da mesma titularidade.
            </Typography>
          </li>
        </ul>
      </div>

      <Box mt="1.5rem" display="flex" flexDirection="column" gap="15px">
        <Textfield
          value={values[1].legal_name}
          label="Nome do titular"
          disabled
        />
        <Textfield value={values[1].document} label="CPF/CNPJ" disabled />
        <AutoCompleteBank control={control} name="bank" />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <InputController control={control} name="agency">
              <Textfield
                placeholder="0000"
                label="Número da agência bancária"
              />
            </InputController>
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputController control={control} name="account">
              <Textfield placeholder="0000000" label="Número da conta" />
            </InputController>
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputController control={control} name="account_dv">
              <Textfield placeholder="00" label="Digito verificador da conta" />
            </InputController>
          </Grid>
        </Grid>
      </Box>

      <Box mt={'3.5rem'} display="flex" gap={1.5}>
        <Button
          fullWidth
          variantButton="secondary"
          type="button"
          onClick={handleBackStep}
          sx={{
            backgroundColor: 'white'
          }}
        >
          Voltar
        </Button>
        <Button fullWidth variantButton="primaryGreen" type="submit">
          Continuar
        </Button>
      </Box>
    </form>
  )
}

export default BankDataForm
