import * as yup from 'yup'

export const SchemaModalInvoice = yup.object({
  iss_retention: yup.string().required('ISS retido na fonte é obrigatório.'),
  service_list_code: yup
    .object({
      name: yup.string(),
      value: yup.string()
    })
    .required('Item de serviço obrigatório.'),
  amount: yup.string().required('Valor obrigatório.'),
  description: yup.string().required('Descrição é obrigatório.'),
  nfse_issuer_uuid: yup.string().required('Prestador de serviço obrigatório.'),
  billings_uuid: yup.object(),
  customer_uuid: yup
    .object({
      value: yup.string().required('Cliente é obrigatório.'),
      label: yup.string().required('Cliente é obrigatório.')
    })
    .required(),
  nature_operation: yup
    .string()
    .required('Natureza da Operação é obrigatório.'),
  link_billing: yup.bool()
})

export type ModalInvoiceFieldsSchema = yup.InferType<typeof SchemaModalInvoice>
