import { Icon, Textfield, TextfieldProps } from '@clientbase/clientbase-library'
import { Typography } from '@mui/material'
import styled from '@mui/system/styled'

export const Wrapper = styled('div')(() => ({
  marginTop: '-42px',
  display: 'flex',
  flexDirection: 'column',
  gap: 24
}))

export const Heading = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  color: theme.palette.neutral[200],
  lineHeight: 1
}))

export const valueButtonsWrapper = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
  color: theme.palette.neutral[200],
  lineHeight: 1
}))

export const TextField = styled((props: TextfieldProps) => (
  <Textfield
    {...props}
    placeholder="Pesquisar"
    startAdornment
    endAdornment
    customEndAdornment={<Icon icon="arrowDropDown" />}
  />
))(() => ({}))
