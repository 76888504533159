import { useCallback, useEffect, useState } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

import { Autocomplete, Textfield } from '@clientbase/clientbase-library'
import { Box } from '@mui/material'
import { DataService } from 'api/DataService'

type Bank = {
  name: string
  uuid: string
}

type AutoCompleteBankProps<T extends FieldValues> = {
  control: Control<T, object>
  name: Path<T>
}

const AutoCompleteBank = <T extends FieldValues>({
  control,
  name
}: AutoCompleteBankProps<T>) => {
  const [banks, setBanks] = useState<Bank[]>([])

  const getAutoCompleteBanks = useCallback(async () => {
    const responseCustumersAutocomplete = await DataService({
      type: 'GET',
      url: '/v1/banks'
    })

    if (responseCustumersAutocomplete.status === 200) {
      const banks = responseCustumersAutocomplete.data.records.map(
        (record: { name: string; uuid: string }) => ({
          name: record.name,
          uuid: record.uuid
        })
      )

      setBanks(banks)
    }
  }, [])

  useEffect(() => {
    getAutoCompleteBanks()
  }, [])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          id="bank_select"
          onChange={(_, value) => onChange(value)}
          value={value}
          options={banks}
          autoHighlight
          getOptionLabel={(option) => (option as Bank).name}
          isOptionEqualToValue={(option, value) =>
            (option as Bank).uuid === (value as Bank).uuid
          }
          renderOption={(props, option) => {
            const optionTyped = option as { name: string; value: string }
            return (
              <Box component="li" {...props} key={optionTyped.value}>
                {optionTyped.name}
              </Box>
            )
          }}
          renderInput={(params) => (
            <Textfield
              label="Em qual banco você quer receber os seus pagamentos?"
              error={!!error?.message}
              helperText={error?.message}
              placeholder="Selecione o banco"
              {...params}
            />
          )}
        />
      )}
    />
  )
}

export default AutoCompleteBank
