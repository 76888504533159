import { styled } from '@mui/material'

export const Wrapper = styled('div')(({ theme }) => ({
  borderRadius: 8,
  display: 'grid',
  border: `1px solid ${theme.palette.neutral[700]}`,
  cursor: 'pointer',
  padding: '0px 10px',
  alignItems: 'center',
  gridTemplateColumns: '80% 20%'
}))

export const Text = styled('p')(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  overflow: 'hidden',
  fontSize: '12px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  color: theme.palette.neutral[400]
}))

export const CopyButton = styled('button')(() => ({
  all: 'inherit',
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'center',
  cursor: 'pointer'
}))
