import React, { memo, useEffect, useState } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
  useMediaQuery
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { DataService } from 'api/DataService'

import Can from 'components/Can'
import Logo from 'components/Logo'
import { ModalBalance } from 'components/Modal/ModalBalance'
import ModalReport from 'components/Modal/ModalReport'

import { formatMoney } from 'utils/FormatMoney'

import * as S from './AppBar.styles'
import MerchantAccount from './components/MerchantAccount'
import MobileDropdown from './components/MobileDropdown'
import Search from './components/Search'
import Shortcuts from './components/Shortcuts'
import Updates from './components/Updates'

type AppBarProps = {
  showTopGlobalInfo?: boolean
  setDrawerIsOpen: (drawerIsOpen: boolean) => void
  isExpandedDrawer: boolean
  setIsExpandedMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  isExpandedMobileMenu: boolean
}

const AppBar = ({
  showTopGlobalInfo,
  isExpandedMobileMenu,
  setIsExpandedMobileMenu,
  isExpandedDrawer
}: AppBarProps) => {
  const [refresh, setRefresh] = useState(false)

  const [isOpenModalReport, setIsOpenModalReport] = useState(false)
  const [balance, setBalance] = useState<false | number>(false)
  const [balanceScheduled, setBalanceScheduled] = useState<false | number>(
    false
  )
  const [hiddedBalance, setHiddenBalance] = useState(false)
  const [modalDetailsIsOpen, setModalDetailsIsOpen] = useState(false)

  const refreshTable = () => setRefresh((s) => !s)
  const match = useMediaQuery('(max-width: 770px)', { noSsr: false })

  const handleCloseModalReport = () => {
    setIsOpenModalReport(false)
  }

  const handleOpenMenuDetails = () => {
    setModalDetailsIsOpen(true)
  }

  const handleCloseModalDetails = () => {
    setModalDetailsIsOpen(false)
    refreshTable()
  }

  const handleToggleMobileMenu = () => setIsExpandedMobileMenu((s) => !s)

  const handleBalace = async () => {
    const res = await DataService({
      type: 'GET',
      url: '/v1/merchants/balance'
    })

    setBalance(Number(res.data.balance))
    setBalanceScheduled(Number(res.data.balance_scheduled))
  }

  useEffect(() => {
    handleBalace()
  }, [refresh])

  return (
    <>
      <S.AppBarMUI
        showTopGlobalInfo={showTopGlobalInfo}
        position="fixed"
        isExpandedDrawer={isExpandedDrawer}
        isExpandedMobileMenu={match && isExpandedMobileMenu}
      >
        <S.Toolbar isExpandedMobileMenu={match && isExpandedMobileMenu}>
          {!match && (
            <Box
              display="flex"
              alignItems="center"
              gap="22px"
              minWidth="30%"
              mr={3}
            >
              <Search />
              <Divider flexItem orientation="vertical" />
              <Shortcuts />
            </Box>
          )}
          {!match ? (
            <S.ToolbarContentWrapper>
              {/* <Weather /> - Comentado em 17/10/2023 */}
              <Can roles={['user', 'admin']}>
                <Box display="flex" alignItems="center">
                  <Tooltip title="Visualizar Detalhes">
                    <S.BalanceButton
                      variant="text"
                      onClick={handleOpenMenuDetails}
                    >
                      <Typography variant="smLight">Saldo</Typography>

                      {balance === false && (
                        <Skeleton
                          variant="rectangular"
                          width={64}
                          height={23}
                          sx={{ borderRadius: 2 }}
                        />
                      )}

                      {balance !== false && (
                        <Typography
                          onClick={handleOpenMenuDetails}
                          variant="mdBold"
                        >
                          {hiddedBalance
                            ? 'R$ *****'
                            : formatMoney(Number(balance))}
                        </Typography>
                      )}
                    </S.BalanceButton>
                  </Tooltip>

                  <Tooltip
                    enterTouchDelay={0}
                    title={hiddedBalance ? 'Mostrar saldo' : 'Esconder saldo'}
                  >
                    <IconButton
                      onClick={() => setHiddenBalance(!hiddedBalance)}
                    >
                      <Icon
                        icon={hiddedBalance ? 'visibilityOff' : 'visibility'}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Can>

              <Divider flexItem={true} orientation="vertical" />

              <Updates />

              <MerchantAccount
                hiddedBalance={hiddedBalance}
                setHiddenBalance={setHiddenBalance}
                setIsOpenModalReport={setIsOpenModalReport}
              />
            </S.ToolbarContentWrapper>
          ) : (
            <Box
              width={'100%'}
              display="flex"
              flexDirection="column"
              alignItems="center"
              alignSelf="start"
            >
              <S.MobileWrapper>
                <Logo id="responsive-logo" />
                <S.Actions>
                  <Search />
                  <Updates />
                  <IconButton onClick={handleToggleMobileMenu}>
                    <FontAwesomeIcon
                      icon={faBars}
                      style={{
                        width: 25,
                        height: 25,
                        fontSize: 22,
                        color: 'black'
                      }}
                    />
                  </IconButton>
                </S.Actions>
              </S.MobileWrapper>

              <MobileDropdown
                balance={balance}
                setIsOpenModalReport={setIsOpenModalReport}
                handleOpenMenuDetails={handleOpenMenuDetails}
                setIsExpandedMobileMenu={setIsExpandedMobileMenu}
                isExpandedMobileMenu={isExpandedMobileMenu}
              />
            </Box>
          )}
        </S.Toolbar>
        {/*match && isExpandedMobileMenu && <GlobalSearch />*/}
      </S.AppBarMUI>

      {isOpenModalReport && (
        <ModalReport
          handleCloseReportModal={handleCloseModalReport}
          isOpenModalReport={isOpenModalReport}
        />
      )}

      {modalDetailsIsOpen && (
        <Can roles={['admin', 'user']}>
          <ModalBalance
            balance={balance}
            balanceScheduled={balanceScheduled}
            handleClose={handleCloseModalDetails}
            isOpen={modalDetailsIsOpen}
          />
        </Can>
      )}
    </>
  )
}
export default memo(AppBar)
