import { useContext, useEffect, useState } from 'react'

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { customerChargesList } from 'api/requests'
import { AuthContext } from 'context/AuthContext'
import { roles } from 'models/User'
import { ParsedUrlQuery } from 'querystring'
import {
  CustomerCharges,
  customerChargesTableMapper
} from 'templates/v2/Charge/components/ChargeTable'
import { NfseStatus } from 'templates/v2/Charge/components/ChargeTable/ChargeTable'

import { SelectParams } from 'components/DynamicTable/DynamicTable.interface'
import { DynamicTableSkeleton } from 'components/DynamicTable/skeleton'
import EmptyCharge from 'components/Empty/EmptyCharge'
import { Table } from 'components/Table'
import { HeadCell } from 'components/Table/Table.interface'

import browserQueryToQueryString from 'utils/query-string'

export interface DataCustomerCharges {
  error: boolean
  records: Record[]
  meta: Meta
  statistics: StatisticsMapperParams
}

export type StatisticsMapperParams = {
  total_billed: number
  total_count: number
  total_paid: number
  total_received: number
  total_to_receive: number
}

export interface Record {
  customer: Customer
  due_date: string
  date_paid: string | null
  amount_billed: string
  status: string
  uuid: string
  viewed: boolean
  recurrence_uuid: string
  nfse_status: NfseStatus
  nfse: Nfse
}

export interface Nfse {
  status: NfseStatus
  uuid: string
}

export interface Customer {
  name: string
  nickname: string
  uuid: string
}

interface Meta {
  current_page: number
  next_page: number
  prev_page: any
  total_pages: number
  total_count: number
}

const tableHeader = [
  { id: 'name', label: 'Cliente' },
  { id: 'dueDate', label: 'Vencimento' },
  { id: 'datePaid', label: 'Pagamento' },
  { id: 'amountBilled', label: 'Valor' },
  { id: 'status', label: 'Status' },
  { id: 'actions', label: '' }
] as HeadCell[]

type ChargeTableProps = {
  baseFilters: { [key in string]: string } | ParsedUrlQuery
  select: SelectParams
}

const ChargeTable = ({ baseFilters, select }: ChargeTableProps) => {
  const { user } = useContext(AuthContext)
  const AUTHORIZATION = roles[user?.role || 'guest']
  const [loading, setLoading] = useState(true)

  const [persistData, setPersistData] = useState<any>({ uuid: '', status: '' })

  const [customerCharges, setCustomerCharges] = useState({} as CustomerCharges)

  const handleGetCustomerCharges = async () => {
    try {
      setLoading(true)
      setCustomerCharges({} as CustomerCharges)

      const response = await customerChargesList<DataCustomerCharges>({
        filter_by: browserQueryToQueryString(
          baseFilters as { [key in string]: string }
        )
      })

      const data = response.data
      const actions = { AUTHORIZATION, notViewActions: true }
      const customerChargeTable = customerChargesTableMapper({ data, actions })

      setCustomerCharges(customerChargeTable)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (
      persistData.uuid !== baseFilters.customer_uuid ||
      persistData.status !== baseFilters.status
    ) {
      setPersistData({
        uuid: baseFilters.customer_uuid,
        status: baseFilters.status,
        pageSize: 500
      })

      handleGetCustomerCharges()
    }
  }, [baseFilters])

  if (loading) {
    return <DynamicTableSkeleton />
  }

  return !customerCharges.rows.length ? (
    <Box>
      <Typography
        variant="mdLight"
        color="#A3A6A2"
        display="flex"
        justifyContent="center"
      >
        Selecione uma ou mais cobranças:
      </Typography>
      <Box pt={5}>
        <EmptyCharge />
      </Box>
    </Box>
  ) : (
    <>
      <Typography
        variant="mdLight"
        color="#757675"
        display="flex"
        justifyContent="center"
      >
        Selecione uma ou mais cobranças:
      </Typography>
      <Table
        header={tableHeader}
        page={0}
        rowsPerPage={500}
        hoverInRow
        disableFilter
        hiddePagination
        select={select}
        {...customerCharges}
      />
    </>
  )
}

export default ChargeTable
