import {
  Accordion as CAccordion,
  AccordionDetails as CAccordionDetails,
  AccordionSummary as CAccordionSummary,
  Paper,
  styled
} from '@mui/material'

export const Wrapper = styled('div')(() => ({
  width: '100%',
  marginBottom: '36px'
}))

export const Accordion = styled(CAccordion)(() => ({
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
  borderRadius: '6px',
  '.MuiAccordionSummary-expandIconWrapper': {
    display: 'none'
  }
}))

export const AccordionSummary = styled(CAccordionSummary)(() => ({
  marginTop: '10px',
  background: 'transparent',
  '.MuiAccordionSummary-content': {
    margin: '12px 0px'
  }
}))

export const AccordionDetails = styled(CAccordionDetails)(() => ({
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  borderTop: '1px solid #E3E6E3',
  padding: '8px 16px'
}))

export const PopperWrapper = styled(Paper)(() => ({
  padding: '14px 10px',
  background: '#525252',
  color: '#FFFFFF',
  maxWidth: 286,
  position: 'relative',
  marginBottom: 18,

  '&::after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translate(-80%, 13%) rotate(-135deg)',
    borderRight: '1.5rem solid transparent',
    borderLeft: '1.5rem solid #525252',
    borderBottom: '1.5rem solid transparent'
  }
}))
