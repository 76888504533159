import { useContext } from 'react'

import { Loader } from '@clientbase/clientbase-library'
import { AuthContext } from 'context/AuthContext'
import { ExtendedSession } from 'pages/api/auth/[...nextauth]'
import AuthTemplate from 'templates/AuthTemplate'

import { FormLogin } from './FormLogin/FormLogin'

export interface FormLogin {
  email: string
  password: string
}
interface LoginTemplateProps {
  session: ExtendedSession
}
const LoginTemplate = ({ session }: LoginTemplateProps) => {
  const { isAuthenticated } = useContext(AuthContext)

  if (isAuthenticated) {
    return <Loader />
  }

  return (
    <AuthTemplate>
      <FormLogin session={session} />
    </AuthTemplate>
  )
}

export default LoginTemplate
