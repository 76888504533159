import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 36,

  overflow: 'auto',
  maxWidth: 1615,
  margin: '0 auto'
})

export const Grid = styled('div')({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '5fr 3fr',
  gridTemplateRows: 'auto',
  gap: 21,

  '@media (max-width: 700px)': {
    gridTemplateColumns: '1fr'
  }
})

export const FieldWrapper = styled('fieldset')({
  width: '100%',
  margin: 0,
  padding: '0',

  border: '1px solid #EDEBEB',
  borderRadius: 4,
  boxSizing: 'border-box'
})

export const Legend = styled('legend')({
  display: 'none'
})

export const Heading = styled('h3')(() => ({
  background: '#F8F8F8',
  padding: '4px 8px',
  width: '100%',

  font: 'normal 600 16px/140% Inter, sans-serif',
  textAlign: 'center',
  color: '#1E1E1E',

  margin: '0 auto',
  boxSizing: 'border-box',
  cursor: 'default'
}))

export const Content = styled('div')({
  padding: '18px 10px 10px',
  display: 'flex',
  flexDirection: 'column',
  gap: 36
})

export const File = styled('label')({
  display: 'flex',
  alignItems: 'center',
  width: '100%'
})

export const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 16
})

type ItemsWrapperParams = { minWidth?: number }

export const ItemsWrapper = styled('div')<ItemsWrapperParams>(
  ({ minWidth = 200, theme }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(${minWidth}px, 1fr))`,
    gridTemplateRows: 'auto',
    gap: 30,

    [theme.breakpoints.down(1300)]: {
      gridTemplateColumns: `auto`
    }
  })
)
