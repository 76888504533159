import { Grid, styled } from '@mui/material'

export const TopBarGlobalInfo = styled(Grid)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '50px',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down(770)]: {
    height: '150px'
  },
  backgroundColor: theme.palette.info[100],
  zIndex: '1000',
  //boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  fontWeight: 'bold'
}))

export const Link = styled('a')(({ theme }) => ({
  margin: 0,
  padding: 0,
  [theme.breakpoints.down(800)]: {
    fontSize: '13px'
  },
  fontWeight: 400,
  cursor: 'pointer'
}))
