import { styled } from '@mui/material'

export const Wrapper = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '20px',
  '& img': {
    objectFit: 'fill'
  }
}))
