import Image from 'next/image'

import * as S from './EmptyCharge.styles'

type EmptyPlanProps = {
  handleOpenModal?: () => void
  minimal?: boolean
  fontIsThini?: boolean
}

const EmptyCharge = ({ minimal, fontIsThini }: EmptyPlanProps) => (
  <S.Wrapper>
    {!minimal && (
      <S.Header>
        <S.Heading>Ops, não encontramos nenhum resultado.</S.Heading>
        <S.Subtitle>Tente outra categoria acima 😉</S.Subtitle>
      </S.Header>
    )}

    <S.ImageWrapper data-fontIsThini={fontIsThini}>
      <Image
        src="/images/empty/charge-nota.png"
        alt="imagem representando os planos"
        layout="fill"
        quality={100}
      />
    </S.ImageWrapper>
  </S.Wrapper>
)

export default EmptyCharge
