import styled from '@mui/system/styled'

export const ErrorLabel = styled('div')(() => ({
  background: '#ffebeb',
  padding: 14,
  borderRadius: 8,
  marginTop: 4,
  font: 'normal 400 14px Inter, sans-serif'
}))

export const Fieldset = styled('fieldset')(() => ({
  padding: 0,
  margin: 0,
  border: 0
}))

export const Legend = styled('legend')(() => ({
  marginBottom: 9
}))

export const CheckboxesWrapper = styled('div')(() => ({
  margin: 0,
  background: '#FFFFFF',
  border: '1px solid #D7D7D7',
  borderRadius: 8,
  overflow: 'hidden'
}))

type LabelProps = {
  inputIsChecked?: boolean
}

const labelModifiers = {
  actived: {
    color: '#FFFFFF',
    background: '#56BB4D',

    '& path': {
      fill: '#FFF'
    },

    '&:hover': {
      background: '#56AA4D'
    }
  },
  disabled: {
    color: '#AAA',
    background: '#FAFAFA',

    '& path': {
      fill: '#AAA'
    }
  }
}

export const Label = styled('label')<LabelProps>(({ inputIsChecked }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 21,
  transition: 'background 200ms',

  padding: '12px 15px',

  font: 'normal 600 14px Inter, "sans-serif"',

  '&:hover': {
    background: '#EAEAEA'
  },

  '&': {
    ...(inputIsChecked && labelModifiers.actived)
  }
}))

export const CheckboxWrapper = styled('div')(() => ({
  '&:not(:last-of-type)': {
    borderBottom: '1px solid #D7D7D7'
  },

  '& input:not(:disabled) ~ label': {
    cursor: 'pointer'
  },

  '& input': {
    all: 'unset'
  },

  '& input[data-actived="false"]:focus ~ label': {
    background: '#56BB4D20'
  },

  '& input[data-actived="true"]:focus ~ label': {
    background: '#56AA4D'
  },

  '& input:disabled ~ label': {
    ...labelModifiers.disabled
  }
}))

export const Wrapper = styled('div')(() => ({}))

export const Divider = styled('div')(() => ({
  borderRight: '1px solid #CBCBCB'
}))
