import React, { useEffect, useState } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { DataService } from 'api/DataService'
import moment from 'moment'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'

import { theme } from 'styles/theme'

import Dot from './components/CircularProgress'
import * as S from './styles'

export type QuotaParams =
  | {
      stage: string
      free_tier: boolean
      expiresIn: string
      billing: {
        max: number
        actual: number
      }
      nfse: {
        max: number
        actual: number
      }
    }
  | undefined

const TopBarGlobalInfo = () => {
  const [quota, setQuota] = useState<QuotaParams>()
  const isMobile = useMediaQuery('(max-width: 770px)', { noSsr: false })
  const { push } = useRouter()

  const { ['nextauth.free_tier']: free_tier } = parseCookies()

  const getData = async () => {
    const response = await DataService({
      type: 'GET',
      url: `/v1/onboarding/get_status_activation`
    })
    setQuota({
      stage: response.data.record.stage,
      free_tier: response.data.record.free_tier,
      expiresIn: response.data.record.free_tier_end_date,
      billing: {
        max: response.data.record.free_tier_billing_quota,
        actual:
          response.data.record.free_tier_billing_quota -
          response.data.record.free_tier_billing_current_quota
      },
      nfse: {
        max: response.data.record.free_tier_nfse_quota,
        actual:
          response.data.record.free_tier_nfse_quota -
          response.data.record.free_tier_nfse_current_quota
      }
    })
  }

  useEffect(() => {
    if (free_tier) {
      getData()
    }
  }, [])

  return (
    <S.TopBarGlobalInfo container>
      {/* rounded corners */}
      <Box
        sx={{
          backgroundColor: '#294266',
          width: '20px',
          height: '20px',
          bottom: '-20px',
          position: 'absolute'
        }}
      ></Box>
      <Box
        sx={{
          content: '""',
          position: 'absolute',

          width: '20px',
          height: '20px',
          backgroundColor: '#f2f5f2',
          clipPath: 'circle(90.4% at 90% 90%)',
          bottom: '-20px'
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: '#294266',
          width: '20px',
          height: '20px',
          right: 0,
          bottom: '-20px',
          position: 'absolute'
        }}
      ></Box>
      <Box
        sx={{
          content: '""',
          position: 'absolute',

          width: '20px',
          height: '20px',
          right: 0,
          backgroundColor: isMobile ? '#f2f5f2' : '#fcfcfc',
          clipPath: 'circle(90.4% at 10% 90%)',
          bottom: '-20px'
        }}
      ></Box>
      {/* rounded corners */}

      <Grid
        display="flex"
        justifyContent="start"
        md={4}
        sm={3}
        xs={12}
        item
        sx={{
          [theme.breakpoints.down(960)]: {
            justifyContent: 'center'
          }
        }}
        padding="0px 35px"
      >
        {quota?.free_tier && (
          <Typography
            sx={{
              [theme.breakpoints.down(1250)]: {
                fontSize: '13px'
              }
            }}
            color="#ffff"
            variant="mdLight"
          >
            10 cobranças e 10 NFS-e grátis - até{' '}
            {moment(quota?.expiresIn).format('DD/MM/YYYY')}
          </Typography>
        )}
      </Grid>

      <Grid
        display="flex"
        justifyContent="center"
        md={4}
        sm={6}
        xs={12}
        item
        gap="30px"
        flexWrap={'wrap'}
        padding="0px 35px"
      >
        {quota?.free_tier && (
          <>
            {quota?.billing && <Dot label="Cobranças" value={quota?.billing} />}
            {quota?.nfse && <Dot label="NFS-e" value={quota?.nfse} />}
          </>
        )}
      </Grid>
      <Grid
        display="flex"
        justifyContent="end"
        item
        md={4}
        sm={3}
        xs={12}
        padding="0px 35px"
        sx={{
          [theme.breakpoints.down(960)]: {
            justifyContent: 'center',
            padding: '0px 0px'
          }
        }}
        flexWrap={'wrap'}
        gap={'8px'}
        alignItems="center"
      >
        {quota?.stage === 'pending' && (
          <>
            <S.Link
              onClick={() => push('/ativar-conta')}
              sx={{ display: 'inline-flex', alignItems: 'center', gap: '5px' }}
            >
              Concluir cadastro
              <Icon
                onClick={() => push('/ativar-conta')}
                icon="open"
                width="21px"
                height="21px"
                ml="6px"
                sx={{ cursor: 'pointer', color: '#ffff' }}
              />
            </S.Link>
          </>
        )}
      </Grid>
    </S.TopBarGlobalInfo>
  )
}

export default TopBarGlobalInfo
