import axios from 'axios'
import { SignOutParams, SignOutResponse } from 'next-auth/react'

interface handleGoogleSignOutProps {
  accessToken: string
  signOut?: <R extends boolean = true>(
    options?: SignOutParams<R> | undefined
  ) => Promise<R extends true ? undefined : SignOutResponse>
}

export const handleGoogleSignOut = async ({
  accessToken,
  signOut
}: handleGoogleSignOutProps) => {
  if (accessToken) {
    const googleLogoutUrl = 'https://accounts.google.com/o/oauth2/revoke'

    await axios.get(googleLogoutUrl, {
      params: { token: accessToken }
    })
  }
  if (signOut) {
    await signOut({ redirect: false })
  }
}
