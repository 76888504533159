/* eslint-disable sort-keys */
import { BillingsParams } from 'models/Billings'
import { constructorCustomer, CustomerParams } from 'models/Customers'
import { constructorDiscounts, DiscountParams } from 'models/Discounts'
import { constructorNfseScheduled, Negativation } from 'models/Invoice'
import { NfseScheduled } from 'models/NFe'
import { constructorProducts, Products } from 'models/Plans'
import {
  constructorCreditCards,
  RecurrenceCardParams
} from 'models/RecurrenceCard'
interface Historic {
  event: string
  description: string
  date_change: string
  user: string
}
export interface RecurrencesParams {
  uuid: string
  status: string
  frequency: string
  totalCycles: number
  dueDay: number
  firstDueDate: string
  amount: string
  interestPolicy: boolean
  discountDays: number
  discountAmount: string
  discounts: DiscountParams[]
  recurrenceCards: RecurrenceCardParams[]
  description: string
  createdAt: string
  updatedAt: string
  paymentType: string
  expirationField: number
  discountPolicy: boolean
  currentCycle: number
  customer: CustomerParams
  billings: BillingsParams[]
  customerUuid: string
  merchantUuid: string
  recurrencePlan?: Products
  historic?: Historic[]
  interestFee: string
  interestFine: string
  nfsesScheduled?: NfseScheduled
  nfse_issuer_uuid?: string
  nfse_uuid?: string
  nfsePolicy?: string
  metadata: any
  negativation: Negativation
}

export function constructorRecurrences(data: any) {
  const billings: BillingsParams[] = data?.billings?.reverse()
    ? data?.billings.map((billing?: any) => {
        return {
          antecipated: billing?.antecipated,
          amountPaid: billing?.amount_paid,
          amountBilled: billing?.amount_billed,
          createdAt: billing?.created_at,
          customerUuid: billing?.customer_uuid,
          datePaid: billing?.date_paid,
          description: billing?.description,
          dirty: billing?.dirty,
          discountAmount: billing?.discount_amount,
          discountDays: billing?.discount_days,
          discountPolicy: billing?.discount_policy,
          dueDate: billing?.due_date,
          interestPolicy: billing?.interest_policy,
          merchantUuid: billing?.merchant_uuid,
          paymentType: billing?.payment_type,
          recurrenceCycle: billing?.recurrence_cycle,
          status: billing?.status,
          updatedAt: billing?.updated_at,
          uuid: billing?.uuid,
          recurrence_cards: billing?.recurrence_cards,
          negativation: billing?.negativation
        }
      })
    : []
  return {
    billings,
    uuid: data?.uuid,
    status: data?.status,
    frequency: data?.frequency,
    totalCycles: data?.total_cycles,
    dueDay: data?.due_day,
    firstDueDate: data?.first_due_date,
    amount: data?.amount,
    interestPolicy: data?.interest_policy === 'no_interest' ? false : true,
    discounts: data?.discounts ? constructorDiscounts(data?.discounts) : [],
    discountDays: data?.discount_days,
    recurrenceCards: data?.recurrence_cards
      ? constructorCreditCards(data?.recurrence_cards)
      : [],
    discountAmount: data?.discount_amount,
    description: data?.description,
    createdAt: data?.created_at,
    updatedAt: data?.updated_at,
    paymentType: data?.payment_type,
    discountPolicy: data?.discount_policy === 'no_discount' ? false : true,
    nfsePolicy: data?.nfse_policy,
    currentCycle: data?.current_cycle,
    customer: data?.customer ? constructorCustomer(data?.customer) : [],
    expirationField: data.limit_days_for_payment,
    customerUuid: data?.customer_uuid,
    merchantUuid: data?.merchant_uuid,
    recurrencePlan: data?.product
      ? constructorProducts([data?.product])[0]
      : undefined,
    negativation: data.negativation,
    historic: data?.historic,
    interestFee: data?.interest_fee,
    interestFine: data?.interest_fine,
    nfsesScheduled: data?.nfse_scheduled
      ? constructorNfseScheduled(data?.nfse_scheduled)
      : undefined,
    nfse_issuer_uuid: data?.nfse_issuer_uuid,
    nfse_uuid: data?.nfse_uuid,
    metadata: data?.metadata
  } as RecurrencesParams
}
