import React, { createContext, useContext, useMemo, useState } from 'react'

import { baseURL } from 'api/axios'
import { DataService } from 'api/DataService'
import axios from 'axios'
import { useSession } from 'next-auth/react'
import Router from 'next/router'
import { parseCookies, setCookie } from 'nookies'
import { REDIRECT_URL_BY_ROLE } from 'pages'
import { ExtendedSession } from 'pages/api/auth/[...nextauth]'

import { handleGoogleSignOut } from 'components/RegisterForm/components/GoogleAuth/utils/logoutGoogleAuth'

import decodeBase64 from 'utils/decode-base64-to-string'
import { destroySession } from 'utils/DestroySession'
import encodeBase64 from 'utils/encode-to-base64'

type Props = {
  children: React.ReactNode
}

export type UserStatus =
  | 'demo'
  | 'rejected'
  | 'suspended'
  | 'blocked'
  | 'trial'
  | 'enabled'
  | 'checking'

export type UserRole =
  | 'admin'
  | 'operator'
  | 'financial_operator'
  | 'user'
  | 'guest'
export type User = {
  uuid: string
  name: string
  logo?: string
  automatic_transfer: boolean | string
  pro: boolean
  nfse: boolean
  role: UserRole
  status: UserStatus
  email: string
  stage: string
  document: string
  installment_billings: boolean
  merchant_user_name: string
  portal_personalized: boolean
  subdomain?: string
}

type SignInParams = {
  email: string
  password?: string
  id_token?: string
  login_type?: 'email' | 'google'
}

type SignInResponse = {
  data: { records: object }
  error: string
  message: string
  status: number
}

export const initialAuthProviderValues = {
  user: null,
  isAuthenticated: false,
  setUser: () => null,
  signIn: () => null,
  signInWithToken: () => null,
  signOut: () => null
}

export type AuthContextType = {
  isAuthenticated: boolean
  user: User | null
  setUser: React.Dispatch<React.SetStateAction<User | null>>
  signIn: (data: SignInParams) => Promise<SignInResponse>
  signInWithToken: (data: { token: string }) => void
  signOut: () => void
  stepCreating: string
  setStepCreating: React.Dispatch<React.SetStateAction<string>>
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthProvider({ children }: Props) {
  const getUser = () => {
    const {
      ['nextauth.token']: token,
      ['nextauth.user']: name,
      ['nextauth.user_logo_url']: logo,
      ['nextauth.automatic_transfer']: automatic_transfer,
      ['nextauth.status']: status,
      ['nextauth.role']: role,
      ['nextauth.pro']: pro,
      ['nextauth.nfse']: nfse,
      ['nextauth.uuid']: uuid,
      ['nextauth.email']: email,
      ['nextauth.installment_billings']: installment_billings,
      ['nextauth.merchant_user_name']: merchant_user_name,
      ['nextauth.document']: document,
      ['nextauth.subdomain']: subdomain
    } = parseCookies()

    if (token && role) {
      const decryptRole = decodeBase64(role)
      const isValidRole = Object.keys(REDIRECT_URL_BY_ROLE).find(
        (k) => k === decryptRole
      )

      if (!isValidRole) {
        destroySession()
        return null
      }

      return {
        uuid,
        automatic_transfer,
        logo,
        name,
        status: status as UserStatus,
        pro: pro === 'true',
        role: decryptRole,
        email,
        installment_billings: installment_billings === 'true',
        merchant_user_name,
        document,
        nfse: nfse === 'true',
        subdomain
      } as User
    } else {
      destroySession()
      return null
    }
  }

  const [user, setUser] = useState<User | null>(getUser)
  const [stepCreating, setStepCreating] = useState<string>('')

  const handleWithLogin = async ({
    response,
    token
  }: {
    response: any
    token?: string
  }) => {
    if (response.status === 200) {
      const {
        uuid,
        logo_url,
        name,
        automatic_transfer,
        role,
        status,
        pro,
        email,
        document,
        installment_billings,
        merchant_user_name,
        merchant_user_uuid,
        nfse,
        shortcuts,
        portal_personalized,
        show_nps,
        limit_days_for_payment,
        subdomain,
        stage,
        free_tier
      } = response.data
      const tok = token || response.data.token

      const encryptRole = encodeBase64(role)

      setCookie(undefined, 'nextauth.subdomain', subdomain, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.email', email, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(
        undefined,
        'nextauth.installment_billings',
        installment_billings,
        {
          maxAge: 60 * 60 * 24 * 6
        }
      )

      setCookie(
        undefined,
        'nextauth.limit_days_for_payment',
        limit_days_for_payment,
        {
          maxAge: 60 * 60 * 24 * 6
        }
      )

      setCookie(undefined, 'nextauth.show_nps', show_nps, {
        maxAge: 60 * 60 * 24 * 6
      })
      setCookie(undefined, 'nextauth.stage', stage, {
        maxAge: 60 * 60 * 24 * 6
      })
      setCookie(undefined, 'nextauth.token', tok, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.uuid', uuid, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.status', status, {
        maxAge: 60 * 60 * 24 * 6
      })

      !!logo_url &&
        setCookie(undefined, 'nextauth.user_logo_url', logo_url, {
          maxAge: 60 * 60 * 24 * 6
        })

      setCookie(undefined, 'nextauth.user', name, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.merchant_user_name', merchant_user_name, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.merchant_user_uuid', merchant_user_uuid, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.shortcuts', shortcuts, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.automatic_transfer', automatic_transfer, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.role', encryptRole, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.pro', pro, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.nfse', nfse, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.document', document, {
        maxAge: 60 * 60 * 24 * 6
      })

      setCookie(undefined, 'nextauth.free_tier', free_tier, {
        maxAge: 60 * 60 * 24 * 6
      })

      setUser({
        automatic_transfer,
        name,
        role,
        pro,
        status,
        installment_billings,
        uuid,
        ...(!!logo_url && { logo: logo_url }),
        email,
        merchant_user_name,
        document,
        nfse,
        portal_personalized,
        stage
      })

      Router.push(REDIRECT_URL_BY_ROLE[role])
    }
  }

  async function signIn({
    email,
    password,
    login_type = 'email',
    id_token
  }: SignInParams) {
    setUser(null)
    const data = {
      email: email.trim(),
      login_type,
      ...(!!password && { password }),
      ...(!!id_token && { id_token })
    }

    setCookie(undefined, 'nextauth.email', email, {
      maxAge: 60 * 60 * 24 * 6
    })

    const response = await DataService({ data, url: '/v1/sign_in' })
    await handleWithLogin({ response })
    return response as SignInResponse
  }

  async function signInWithToken({ token }: { token: string }) {
    setUser(null)
    //destroySession()
    const response = await axios.get(`${baseURL}/v1/merchants/preferences`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    await handleWithLogin({ response, token })
  }
  const { data: sessionData } = useSession()
  const sessionDatatyped = sessionData as ExtendedSession
  const signOut = async () => {
    if (sessionDatatyped?.user) {
      await handleGoogleSignOut({
        accessToken: sessionDatatyped?.accessToken as string
      })
    }
    await DataService({
      type: 'PATCH',
      url: '/v2/auth/logout'
    })
    destroySession()
    setUser(null)
    Router.push('/login')
  }

  const value = useMemo(
    () => ({
      isAuthenticated: !!user,
      signIn,
      signOut,
      user,
      signInWithToken,
      setUser,
      setStepCreating,
      stepCreating
    }),
    [user, signIn, signOut, user, signInWithToken, setUser]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuthContext should be used within an AuthContext')
  }

  return context as AuthContextType
}
