import React, { cloneElement } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

import { TextareaProps, TextfieldProps } from '@clientbase/clientbase-library'

export type InputControllerProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T, object>
  errorLabelCustom?: string
  children: React.ReactElement
} & React.InputHTMLAttributes<HTMLInputElement> &
  TextfieldProps &
  TextareaProps

const InputController = <T extends FieldValues>({
  name,
  control,
  children,
  ...props
}: InputControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        cloneElement(children, {
          ...field,
          ...props,
          inputRef: field.ref,
          helperText: error?.message,
          error: !!error?.message
        })
      }
    />
  )
}

export default InputController
