import { Icon, Icons } from '@clientbase/clientbase-library'

interface GradientOpenWithIconProps {
  width: string
  height: string
  colors: any[]
  icon: Icons
}

export const GradientOpenWithIcon = ({
  colors,
  height,
  width,
  icon
}: GradientOpenWithIconProps) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        {colors.map((color, index) => (
          <stop key={index} offset={index} stopColor={color} />
        ))}
      </linearGradient>
    </svg>
    <Icon icon={icon} sx={{ fill: 'url(#linearColors)', width, height }} />
  </>
)
