import { Button, Dialog } from '@clientbase/clientbase-library'
import { Box, Typography } from '@mui/material'

interface DialogBlockNewInvoiceProps {
  open: boolean
  closeDialog: (open?: boolean) => void
}

const DialogBlockNewInvoice = ({
  open,
  closeDialog
}: DialogBlockNewInvoiceProps) => {
  return (
    <Dialog
      icon="info"
      title={
        <Box display="flex" flexDirection="column">
          <Typography variant="mdBold" fontSize="20px">
            Ei, antes de emitir uma nota, você precisa
          </Typography>
          <Typography variant="mdBold" fontSize="20px">
            cadastrar um prestador.
          </Typography>
        </Box>
      }
      description=""
      setIsOpenDialog={() => closeDialog()}
      isOpenDialog={open}
    >
      <Button
        variantButton="contained"
        onClick={() => closeDialog(true)}
        fullWidth
      >
        Cadastrar prestador
      </Button>
    </Dialog>
  )
}

export default DialogBlockNewInvoice
