import { Icon } from '@clientbase/clientbase-library'
import styled from '@mui/system/styled'
export const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 40
}))
export const InputWrapper = styled('div')(() => ({
  display: 'grid',
  gap: '15px'
}))
export const TextWrapper = styled('div')(() => ({
  display: 'grid',
  backgroundColor: '#F7FAF7',
  borderRadius: '12px'
}))
export const IconStyled = styled(Icon)(() => ({
  height: '15px',
  width: '15px',
  fontSize: '1.2rem',
  color: '#BEC0BE'
}))
