import React from 'react'

import { Box, Typography } from '@mui/material'

interface CircularProgressProps {
  label: string
  value: {
    actual: number
    max: number
  }
}

const Dot = ({ label, value }: CircularProgressProps) => {
  const percentage = (value?.actual / value?.max) * 100
  const radius = 15
  const circumference = 2 * Math.PI * radius
  const offset = circumference - (percentage / 100) * circumference

  return (
    <Box alignItems="center" display="flex" flexWrap={'wrap'} gap="10px">
      <Box position="relative" width={40} height={40}>
        <svg width="40" height="40">
          <circle
            cx="20"
            cy="20"
            r={radius}
            fill="none"
            stroke="#ffffff"
            strokeWidth="3"
            strokeDasharray="1,1"
          />
        </svg>

        <svg
          width="40"
          height="40"
          style={{ position: 'absolute', top: 0, left: 0 }}
        >
          <circle
            cx="20"
            cy="20"
            r={radius}
            fill="none"
            stroke="#ffffff"
            strokeWidth="3"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            style={{
              transition: 'stroke-dashoffset 0.3s ease',
              transform: 'rotate(-90deg)',
              transformOrigin: 'center'
            }}
          />
        </svg>
      </Box>

      <Box display="flex" flexDirection="column" flexWrap="wrap" gap="5px">
        <Typography color="#fff" variant="smLight">
          {label}
        </Typography>
        <Typography color="#fff" variant="xsLight">
          {value.actual} de {value.max}
        </Typography>
      </Box>
    </Box>
  )
}

export default Dot
