import { ParsedUrlQuery } from 'querystring'

import { currency } from './FormatMoney'

const browserQueryToQueryString = (query: { [key in string]: string }) => {
  // eslint-disable-next-line
  const { tab, page, per, sort, id, amount_billed, ...filters } = query

  const pageNumber = Number(page) + 1

  const params = {
    ...(!!page && { page: pageNumber.toString() }),
    ...(!!per && { per }),
    ...(!!sort && { sort })
  }

  const paramsQueryString = new URLSearchParams(params).toString() ?? ''

  const amount =
    !!amount_billed &&
    currency(amount_billed).replaceAll('.', '').replaceAll(',', '.')

  const filterBy = new URLSearchParams({
    ...filters,
    ...(!!amount_billed && { amount_billed: amount || '0' }),
    ...(id && { customer_uuid: id })
  })
    .toString()
    .replaceAll('&', ',')

  return `?${paramsQueryString}${filterBy ? `&filter_by=${filterBy}` : ''}`
}

export const exportReportData = (query: { [key in string]: string }) => {
  // eslint-disable-next-line
  const { tab, page, per, sort, id, amount_billed, ...filters } = query

  const amount = currency(amount_billed ?? '')
    .replaceAll('.', '')
    .replaceAll(',', '.')

  const filter_by = new URLSearchParams({
    ...filters,
    ...(!!amount_billed && { amount_billed: amount }),
    ...(id && { customer_uuid: id })
  })
    .toString()
    .replaceAll('&', ',')
    .replaceAll('%7C', '|')

  return {
    filter_by,
    sort
  }
}

type HandleDeleteFilters = {
  filters: { [key in string]: string } | ParsedUrlQuery
  options: string[]
}

export const handleDeleteQueryFilters = ({
  filters,
  options
}: HandleDeleteFilters) => {
  const currencyFilters = filters
  options.map((filter) => delete currencyFilters[filter])
  return currencyFilters
}

export default browserQueryToQueryString
