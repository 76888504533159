import * as yup from 'yup'

export const FormProductSchema = yup.object({
  namePlan: yup.string().required('Campo obrigatório.'),
  icon: yup.string().when('documentUuid', {
    is: !undefined,
    then: yup.string().required('Campo obrigatório.')
  }),
  amount: yup
    .string()
    .typeError('Valor obrigatório.')
    .required('Valor obrigatório.'),
  description: yup.string(),
  billingDescription: yup.string().nullable(),
  documentUuid: yup.string(),
  discountPolicy: yup.boolean(),
  dayDate: yup.number(),
  interestPolicy: yup.boolean(),
  payments: yup
    .array()
    .min(1, 'Método de pagamento obrigatório.')
    .required('Método de pagamento obrigatório.'),
  status: yup.number(),
  frequency: yup.string(),
  totalCycles: yup.number(),
  credit_card_uuid: yup.object(),
  interestFee: yup.string().when('interestPolicy', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  interestFine: yup.string().when('interestPolicy', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  chargeType: yup.number(),
  dueType: yup.string(),
  dueDay: yup.number().when('dueType', {
    is: 'specified_charge',
    then: yup.number().required('Campo obrigatório.'),
    otherwise: yup.number().when('dueType', {
      is: 'delay_charge',
      then: yup.number().required('Campo obrigatório.'),
      otherwise: yup.number()
    })
  }),
  proratedCharge: yup.boolean().when('chargeType', {
    is: 1,
    then: yup.boolean().when('dueType', {
      is: 'specified_charge',
      then: yup.boolean().required('Campo obrigatório.'),
      otherwise: yup.boolean()
    }),
    otherwise: yup.boolean()
  }),
  max_installments: yup.number().when('payments', {
    is: (val: string[]) => val?.includes('credit_card_installments'),
    then: yup
      .number()
      .required('Número de parcelas obrigatório')
      .min(2, 'Número minimo de parcelas é 2')
      .max(12, 'Número máximo de parcelas é 12'),
    otherwise: yup.number().nullable().notRequired()
  }),
  max_installments_without_fee: yup.number().when('payments', {
    is: (val: string[]) => val?.includes('credit_card_installments'),
    then: yup
      .number()
      .required('Número de parcelas sem juros obrigatório')
      .min(1, 'Número minimo de parcelas sem juros é 0')
      .max(12, 'Número máximo de parcelas sem juros é 12'),
    otherwise: yup.number().nullable().notRequired()
  }),
  interestInvoice: yup.boolean(),
  copy_description: yup.boolean(),
  iss_retention: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  service_list_code: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  descriptionInvoice: yup
    .string()
    .when(['interestInvoice', 'copy_description'], {
      is: (interestInvoice: boolean, copy_description: boolean) =>
        interestInvoice === true && copy_description === false,
      then: yup.string().required('Campo obrigatório.'),
      otherwise: yup.string().nullable()
    }),
  nfse_issuer_uuid: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  amount_type: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  issue_when: yup.string().when('interestInvoice', {
    is: true,
    then: yup
      .string()
      .oneOf(
        ['on_billing_issued', 'on_billing_paid'],
        'Você deve selecionar uma das opções.'
      )
      .required('Campo obrigatório.'),
    otherwise: yup.string()
  }),
  nature_operation: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  amountCustom: yup.string().when('amount_type', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  })
})

export type FormProductParams = yup.InferType<typeof FormProductSchema>
