import React, { useContext, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { toast } from 'react-toastify'

import { AuthContext } from 'context/AuthContext'
import { GetServerSideProps } from 'next'
import { getSession, signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { ExtendedSession } from 'pages/api/auth/[...nextauth]'
import LoginTemplate from 'templates/Login'

import Loader from 'components/Loader'
import NextHead from 'components/NextHead'
import { handleGoogleSignOut } from 'components/RegisterForm/components/GoogleAuth/utils/logoutGoogleAuth'

import { destroySession } from 'utils/DestroySession'

export type LoginProps = {
  token: string
  sessionProp: ExtendedSession
}

const Login = ({ token, sessionProp: session }: LoginProps) => {
  const tagManagerArgs = {
    gtmId: 'GTM-P7GRR3JZ'
  }
  const { query, push } = useRouter()
  useEffect(() => {
    if (session) {
      handleEndSession()
    }
  }, [session])
  useEffect(() => {
    if (query.error) {
      toast.info('Email já cadastrado! Efetue login 🙂')
      push('/login', undefined, { shallow: true })
    }
  }, [query])
  if (process.browser) {
    TagManager.initialize(tagManagerArgs)
  }

  const { signInWithToken } = useContext(AuthContext)
  if (token) {
    signInWithToken({ token })
    return <Loader />
  }
  const handleEndSession = async () => {
    try {
      await handleGoogleSignOut({
        accessToken: session?.accessToken as string,
        signOut
      })
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <React.Fragment>
      <NextHead title="Login" />
      <LoginTemplate session={session} />
    </React.Fragment>
  )
}

export default Login

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = (await getSession({ req: context.req })) as ExtendedSession
  const { ['nextauth.token']: token, ['nextauth.role']: role } =
    parseCookies(context)
  if (token && role && !context?.query?.auth) {
    return {
      redirect: {
        destination: '/visao-geral',
        permanent: false
      }
    }
  } else {
    destroySession()
  }

  return {
    props: {
      generatedAt: new Date().toString(),
      token: context?.query?.auth || '',
      sessionProp: session
    }
  }
}
